import React, { useState } from "react"
import "./RegisterInterestModule.scss"
import { Container } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import { addRollWhatsapp, getWhatsRedirectURL, gtagReportConversion, parseHTML } from "@lib/utils"
import { teamsURL } from "@lib/urls"
// import RegisterInterestForm from "@components/forms/RegisterInterestForm"
import loadable from "@loadable/component"
import GGFXImage from "@components/elements/GGFXImage"
import PhoneIconPC from "@components/icons/PhoneIconPC"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import Brightcall from "@components/Brightcall/Brightcall"

const RegisterInterestForm = loadable(() =>
  import("@components/forms/RegisterInterestForm")
)

const RegisterInterestModule = ({ offplan, message, propertyURL }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiGlobalModule {
        ggfx_results {
          ...STRAPI_GGFX_RESULTFragment
        }
        strapi_id
        register_interest_form {
          bg_image {
            url
          }
          description {
            data {
              description
            }
          }
          strapi_id
          title
          
          # team {
          #   ...teamFragment
          # }
        }
      }
    }
  `)
  const { phone } = useBrandInfo()
  const module = data.strapiGlobalModule.register_interest_form
    const [brignt, setBright] = useState(false)
  if (!module) return null

  const { bg_image, description, title, team } = module
  return (
    <div
      className="register-interest-module-wrap section-l-p"
      id="register-interest"
    >
      <div className="bg-section">
        {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={bg_image.url} alt="bg" /> */}
        <GGFXImage
            ImageSrc={bg_image}
            altText={title}
            imagetransforms={data.strapiGlobalModule?.ggfx_results}
            renderer="srcSet"
            imagename="global-module.bg_image.bg_img"
            strapiID={data.strapiGlobalModule?.strapi_id}
          />
        <div className="overlay" />
      </div>
      <Container className="register-interest-module-container">
        <div className="left-section">
          <h2 className="title">{title}</h2>
          <div className="description">
            {parseHTML(description?.data?.description)}
            <p className="d-block d-md-flex">
               <a
              // href={`tel:${phone}`}
              href="javascript:void(0);"
              className="property-cta"
              onClick={() => 
                {
                  // if (isAboveLargeScreen && isListing) {
                  //   toShowNumber(strapi_id); // Call toShowNumber when condition is met
                  //   gtagReportConversion(`tel:${phone}`)
                  // }
                  // else {
                  //   gtagReportConversion(`tel:${phone}`)
                  // }
                  gtagReportConversion(`tel:${phone}`)
                  setBright(true)
                }
                 }
            >
               <PhoneIconPC />
               <span>Request a Call Back Now</span>
               {/* {isAboveLargeScreen && isListing && strapi_id === shownum ? <span>{phone ? phone?.replace(/ /g, '') : "97143233609"}</span> :<span>Call</span>} */}
            </a>
            <a href={getWhatsRedirectURL(
                "971505389860",
                message
              )}
              target="_blank" 
              onClick={()=> addRollWhatsapp()} class="button whatsapp-icon-btn button-white-outline" rel="noopener noreferrer"><span>Chat with us now</span></a>
            </p>
          </div>
          {brignt && <Brightcall setBright={setBright} developer={offplan?.developer} property={offplan} propertyURL={propertyURL} />}
          {team && (
            <div className="team-card">
              <Link to={`${teamsURL + team.slug}/`} className="image-section">
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={team.image?.url} alt="team-image" />
              </Link>
              <div className="content-section">
                <h3 className="name">{team.name}</h3>
                <p className="position">{team.designation}</p>
                <p className="license">ORN No: {team.license}</p>
              </div>
            </div>
          )}
        </div>
        <div className="right-section">
          <RegisterInterestForm offplan={offplan} />
        </div>
      </Container>
    </div>
  )
}

export default RegisterInterestModule
