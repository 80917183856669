import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const PhoneIconPC = ({ className, color, ...props }) => {
  const stroke = getIconColor(color, "#35373C")

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    className={clsx("phone-icon", className)}
    {...props}>
  <path d="M1.5 4.5C1.5 10.0228 5.97715 14.5 11.5 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V12.0856C14.5 11.7414 14.2658 11.4414 13.9319 11.358L10.9831 10.6208C10.6904 10.5476 10.3823 10.657 10.2012 10.8984L9.5544 11.7608C9.36668 12.0111 9.04201 12.1218 8.74823 12.0142C6.5436 11.2066 4.79344 9.45641 3.98584 7.25177C3.87823 6.95799 3.98891 6.63332 4.2392 6.4456L5.10161 5.79879C5.34302 5.61773 5.45241 5.30964 5.37922 5.01689L4.64202 2.0681C4.55856 1.73422 4.25857 1.5 3.91442 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V4.5Z" stroke="#EE7133" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    // <svg
    //   width="16"
    //   height="17"
    //   viewBox="0 0 16 17"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={clsx("phone-icon", className)}
    //   {...props}
    // >
    //   <path
    //     d="M1.5 5C1.5 10.5228 5.97715 15 11.5 15H13C13.8284 15 14.5 14.3284 14.5 13.5V12.5856C14.5 12.2414 14.2658 11.9414 13.9319 11.858L10.9831 11.1208C10.6904 11.0476 10.3823 11.157 10.2012 11.3984L9.5544 12.2608C9.36668 12.5111 9.04201 12.6218 8.74823 12.5142C6.5436 11.7066 4.79344 9.95641 3.98584 7.75177C3.87823 7.45799 3.98891 7.13332 4.2392 6.9456L5.10161 6.29879C5.34302 6.11773 5.45241 5.80964 5.37922 5.51689L4.64202 2.5681C4.55856 2.23422 4.25857 2 3.91442 2H3C2.17157 2 1.5 2.67157 1.5 3.5V5Z"
    //     stroke={stroke}
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  )
}

export default PhoneIconPC
